import * as React from "react";
import { useMotionTemplate, useMotionValue, motion } from "framer-motion";
import { cn } from "../utils/cn";
import { MouseEventHandler } from "react";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps & { textarea?: boolean }>(
    ({
        textarea = false,
        className,
        type,
        ...props
    }, ref) => {
        const radius = 100; // change this to increase the rdaius of the hover effect
        const [visible, setVisible] = React.useState(false);

        const mouseX = useMotionValue(0);
        const mouseY = useMotionValue(0);

        const handleMouseMove: MouseEventHandler<HTMLDivElement> =
            ({ currentTarget, clientX, clientY }) => {
                const { left, top } = currentTarget.getBoundingClientRect();

                mouseX.set(clientX - left);
                mouseY.set(clientY - top);
            }

        return (
            <motion.div
                style={{
                    background: useMotionTemplate`
                            radial-gradient(
                            ${visible ? radius + "px" : "0px"} circle at ${mouseX}px ${mouseY}px,
                            var(--rose-500),
                            transparent 80%
                            )
                        `,
                }}
                onMouseMove={handleMouseMove}
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
                className="w-full p-[2px] rounded-lg transition duration-300 group/input"
            >
                {textarea ?
                    <textarea
                        {...props}
                        className={cn(
                            `flex w-full text-body md:text-body-md border-none bg-zinc-800 text-white rounded-md px-4 py-3 text-sm  file:border-0 file:bg-transparent 
                            file:text-sm file:font-medium placeholder:text-neutral-400 placeholder-text-neutral-600 
                            focus-visible:outline-none focus-visible:ring-[2px] focus-visible:ring-neutral-600
                            disabled:cursor-not-allowed disabled:opacity-50
                            shadow-[0px_0px_1px_1px_var(--neutral-700)]
                            group-hover/input:shadow-none transition duration-400
                        `, className
                        )}
                        rows={5}
                        {...props}
                    />
                    :
                    <input
                        type={type}
                        className={cn(
                            `flex w-full text-body md:text-body-md border-none bg-zinc-800 text-white rounded-md px-4 py-3 file:border-0 file:bg-transparent 
                                 placeholder:text-neutral-400 placeholder-text-neutral-600 
                                focus-visible:outline-none focus-visible:ring-[2px] focus-visible:ring-neutral-600
                                disabled:cursor-not-allowed disabled:opacity-50
                                shadow-[0px_0px_1px_1px_var(--neutral-700)]
                                group-hover/input:shadow-none transition duration-400
                            `, className
                        )}
                        ref={ref}
                        {...props}
                    />}
            </motion.div>
        );
    }
);
Input.displayName = "Input";

export { Input };

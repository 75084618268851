import { Canvas } from "@react-three/fiber";
import { useLoader } from '@react-three/fiber'
import { OBJLoader } from 'three/addons/loaders/OBJLoader.js';

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Model42() {
    gsap.registerPlugin(ScrollTrigger)

    const obj = useLoader(OBJLoader, '/42.obj')

    useGSAP(() => {
        gsap.to(obj.rotation, {
            duration: 2,
            y: "+=" + Math.PI * 4 / 2,
            scrollTrigger: {
                scrub: 1,
                trigger: ".model ",
                toggleActions: 'restart complete reverse reset',
            }
        });

        gsap.from(".model", {
            duration: 2,
            x: "-=500",
            opacity: 0,
            scrollTrigger: {
                scrub: 1,
                trigger: ".model",
                toggleActions: 'restart complete reverse reset',
                end: "top 25%",
            }
        });
    }, [obj])

    return (
        <div
            className="w-full"
            style={{
                aspectRatio: 4 / 3
            }}>
            <Canvas
                className="model"
                style={{
                    width: "100%",
                    height: "100%",
                }}>
                <ambientLight intensity={Math.PI / 2} />
                <spotLight position={[10, 10, 10]} angle={0.9} penumbra={1} decay={0} intensity={Math.PI} />
                <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
                <primitive object={obj} rotation={[0, Math.PI * -1 / 2, 0]} scale={1.75} />
            </Canvas>
        </div>
    )
}
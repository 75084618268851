import React, { ElementType } from "react";

type Variant =
    | "title"
    | "project-title"
    | "label"
    | "description"
    | "lg"
    | "body"
    | "small"

interface Props {
    variant?: Variant;
    children: React.ReactNode;
    className?: string;
    as?: ElementType;
}

const sizes: Record<Variant, string> = {
    title: "text-title md:text-title-md lg:text-title-lg font-semibold",
    "project-title": "text-project-title md:text-project-title-md lg:text-project-title-lg font-medium",
    label: "text-label md:text-label-md lg:text-label-lg",
    description: "text-description md:text-description-md lg:text-description-lg",
    lg: "text-lg md:text-lg-md lg:text-lg-lg",
    body: "text-body md:text-body-md lg:text-body-lg",
    small: "text-sm",
};

const Typography = ({
    variant = "body",
    children,
    className,
    as = "p"
}: Props) => {
    const As = as
    const sizeClasses = sizes[variant];

    return <As className={sizeClasses + " " + className}>{children}</As>;
};

export default Typography
import Typography from "./Typography";

export default function Title({
    t1,
    t2,
}: {
    t1: string,
    t2: string,
}) {
    return (
        <h2 className="title flex flex-col items-center uppercase relative">
            <Typography className="cursor-scale bg-clip-text text-transparent bg-gradient-to-b from-white to-neutral-400 bg-opacity-50" variant="title">{t1}</Typography>
            <span> </span>
            <Typography className="cursor-scale bg-clip-text text-transparent bg-gradient-to-b from-neutral-300 to-neutral-500 bg-opacity-50" variant="title">{t2}</Typography>
        </h2>
    )
}
import Name from "./Name";
import dd from "../assets/portrait.webp";
import Typography from "./Typography";

export default function Header() {
    return (
        <div className="flex flex-col min-h-lvh gap-10 items-center justify-center">
            <img
                src={dd}
                alt="Portrait of Thaïs Marcon"
                className="parallax -z-10 fixed object-cover h-lvh opacity-25 select-none"
            />

            <Name />

            <Typography as="h1" variant="description" className='uppercase description cursor-scale small text-white'>
                Développeur Web Freelance à Lyon
            </Typography>
        </div>
    )
}
import './index.css';

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import Lenis from 'lenis/react';

import FirebaseProvider from './context/Firebase/FirebaseProvider.tsx';
import AppRouter from './components/AppRouter.tsx';

const firebaseConfig = {
  apiKey: "AIzaSyCgPyXlrROGEx_RVuliLTpk-TCnFRQ8mWs",
  authDomain: "portfolio-4c038.firebaseapp.com",
  projectId: "portfolio-4c038",
  storageBucket: "portfolio-4c038.appspot.com",
  messagingSenderId: "59181904005",
  appId: "1:59181904005:web:4d72fce852ecd4e78739ec",
  measurementId: "G-8LJBBWJ4FG"
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Suspense>
      <FirebaseProvider config={firebaseConfig}>
        <Lenis root>
          <AppRouter />
        </Lenis>
      </FirebaseProvider>
    </Suspense>
  </React.StrictMode>
)

import Button from "./Button";
import Grainy from "./Grainy";
import Typography from "./Typography";

export default function Project({
    title,
    description,
    url,
    image,
    invert = false,
}: {
    title: string,
    description: string,
    url: string,
    image: string,
    invert?: boolean,
}) {
    return (
        <div className={`flex flex-col gap-20 ${invert ? "sm:flex-row-reverse" : "sm:flex-row"}`}>
            <div className='flex flex-col project-title items-start justify-center flex-1 gap-5'>
                <Typography variant="project-title" className="cursor-scale uppercase z-0">
                    {title}
                </Typography>

                <Typography className="cursor-scale small z-0 mb-10">
                    {description}
                </Typography>

                <Button href={url}>
                    Voir le projet
                </Button>
            </div>

            <Grainy
                url={url}
                color='white'
                className={`md:flex-1 overflow-hidden rounded-2xl relative cursor-scale project-illustration-${invert ? "l" : "r"}`}>
                <img
                    src={image}
                    width="500"
                    height="400"
                    alt="title"
                    className="object-cover h-full w-full object-top"
                />
            </Grainy>
        </div>
    )
}
import { cn } from "../utils/cn"
import Typography from "./Typography"

export default function Skill({
    title,
    url,
    image,
    imgClass = "w-24 md:w-32",
}: {
    title?: string,
    url?: string,
    image: string,
    imgClass?: string
}) {
    const Container = url ? "a" : "div"

    return (
        <Container
            {...(url && {
                href: url,
                target: "_blank",
            })}
            className={`skill flex flex-col gap-10 items-center ${url && "cursor-scale"}`}>

            <img
                className={cn("aspect-square object-contain", imgClass)}
                src={image}
                alt={title + " icon"}
            />

            {title && <Typography as="h3" variant="label" className="cursor-scale small">{title}</Typography>}
        </Container>
    )
}
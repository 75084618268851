import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from '@gsap/react';

import { ReactNode, useRef } from 'react';
import Cursor from './Cursor';

function GsapContainer({children}: {children: ReactNode}) {
  const container = useRef<HTMLDivElement>(null!)
  gsap.registerPlugin(ScrollTrigger)

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from(".c", {
      opacity: 0,
      y: "100%",
      stagger: .05,
      duration: .5,
      skewX: "50deg",
    })

    tl.from(".description", {
      opacity: 0,
      y: "100%",
      duration: .5,
    })

    gsap.to(".parallax", {
      scrollTrigger: {
        scrub: 1,
        start: "top top",
        invalidateOnRefresh: true,
        end: "bottom 0%",
        trigger: ".parallax",
        toggleActions: 'restart complete reverse reset',
      },
      left: "100%",
    })

    document.querySelectorAll(".title").forEach(e => {
      gsap.fromTo(e.firstElementChild, {
        x: "-30%",
      }, {
        x: "5%",
        scrollTrigger: {
          scrub: 1,
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
      })

      gsap.fromTo(e.lastElementChild, {
        x: "30%",
      }, {
        x: "-5%",
        scrollTrigger: {
          scrub: 1,
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
      })
    })

    document.querySelectorAll(".project-illustration-r").forEach(e => {
      gsap.from(e, {
        scrollTrigger: {
          scrub: 1,
          end: "bottom 75%",
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
        x: "100%",
        rotate: "45deg"
      })
    })

    document.querySelectorAll(".project-illustration-l").forEach(e => {
      gsap.from(e, {
        scrollTrigger: {
          scrub: 1,
          end: "bottom 75%",
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
        x: "-100%",
        rotate: "-45deg"
      })
    })

    document.querySelectorAll(".scroll-left").forEach(e => {
      gsap.to(e, {
        scrollTrigger: {
          scrub: 1,
          toggleActions: 'restart complete reverse reset',
        },
        x: "-50%",
      })
    })

    document.querySelectorAll(".scroll-right").forEach(e => {
      gsap.to(e, {
        scrollTrigger: {
          scrub: 1,
          toggleActions: 'restart complete reverse reset',
        },
        x: "50%",
      })
    })

    document.querySelectorAll(".project-title").forEach(e => {
      gsap.from(e, {
        scrollTrigger: {
          scrub: 1,
          end: "bottom 75%",
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
        y: "50%",
      })
    })

    document.querySelectorAll(".skill").forEach(e => {
      gsap.from(e, {
        stagger: .05,
        opacity: 0,
        scrollTrigger: {
          scrub: 1,
          end: "bottom 75%",
          trigger: e,
          toggleActions: 'restart complete reverse reset',
        },
        y: "50%",
      })
    })
  }, { scope: container }); // <-- magic

  return (
    <div ref={container} className="overflow-hidden md:cursor-none">
      <Cursor />

      {children}
    </div>
  )
}

export default GsapContainer
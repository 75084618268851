import { ReactNode } from "react";

import grainy from "../assets/grainy.svg";

export default function Header({
    url,
    color,
    children,
    className
}: {
    url: string,
    color: string,
    children: ReactNode,
    className: string
}) {
    return (
        <a
            href={url}
            className={className}
            target="_blank"
            style={{
                height: 400,
                maxWidth: 600,
                background: `linear-gradient(166.9deg, ${color} 53.19%, black 107.69%)`
            }}>

            {children}

            <div
                className="top-0 w-full h-full absolute opacity-20"
                style={{
                    background: "url(" + grainy + ")",
                }} />
        </a>
    )
}
import Typography from "./Typography";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="flex py-20 bg-white">
            <div className='container mx-auto px-4'>
                <div className="flex flex-col gap-5">
                    <Typography className="font-medium text-center uppercase text-black">
                        © 2024 Thaïs Marcon
                    </Typography>

                    <Link to="/legals">
                        <Typography variant="small" className="font-medium text-center uppercase text-black underline">
                            Mentions Légales
                        </Typography>
                    </Link>
                </div>
            </div>
        </div>
    )
}
import { useEffect } from "react";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function Header() {

    useGSAP(() => {
        let mouseX = -20;
        let mouseY = -20;

        const mousemove = (e: MouseEvent) => {
            mouseX = e.clientX
            mouseY = e.clientY
        }

        gsap.to({}, {
            repeat: -1,
            duration: 0.016,
            onRepeat: () => {
                gsap.set(".cursor", {
                    css: {
                        left: mouseX,
                        top: mouseY,
                    }
                })
            }
        });

        addEventListener('mousemove', mousemove)

        return () => removeEventListener('mousemove', mousemove)
    })

    useEffect(() => {
        const cursor = document.querySelector('.cursor');
        const cursorScale = document.querySelectorAll('.cursor-scale');

        if (cursor) {
            cursorScale.forEach(link => {
                const mousemove = () => {
                    cursor.classList.add('grow');
                    if (link.classList.contains('small')) {
                        cursor.classList.remove('grow');
                        cursor.classList.add('grow-small');
                    }
                }
                link.addEventListener('mousemove', mousemove);

                const mouseleave = () => {
                    cursor.classList.remove('grow');
                    cursor.classList.remove('grow-small');
                }
                link.addEventListener('mouseleave', mouseleave);
            })
        }
    })

    return (
        <div
            className="cursor hidden md:flex"
            style={{
                marginLeft: "-20px",
                marginTop: "-20px",
                position: "fixed",
                width: 40,
                height: 40,
                pointerEvents: "none",
                zIndex: 1000,
                border: "2px solid white",
                borderRadius: 20,
                transition: "transform 0.3s ease",
                transformOrigin: "center center",
            }}
        />
    )
}
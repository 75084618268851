import { Navigate, RouteObject, RouterProvider, createBrowserRouter } from "react-router-dom";
import HomeRoute from "../routes/HomeRoute";
import LegalsRoute from "../routes/LegalsRoute";

const AppRouter = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            children: [
                {
                    index: true,
                    element: <HomeRoute />,
                },
                {
                    path: "legals",
                    element: <LegalsRoute />,
                },
                {
                    path: "*",
                    element: <Navigate to="/" />,
                },
            ],
        },
    ] as RouteObject[], { basename: import.meta.env.VITE_BASE_NAME || "/" });

    return <RouterProvider router={router} />
}

export default AppRouter
import Header from '../components/Header';
import Project from '../components/Project';
import Title from '../components/Title';

import Skill from '../components/Skill';

import firebase from "../assets/skills/firebase.svg";
import tailwind from "../assets/skills/tailwind.svg";

import ts from "../assets/skills/ts.svg";
import gsapIcon from "../assets/skills/gsap.svg";
import react from "../assets/skills/react.svg";
import wordpress from "../assets/skills/wordpress.svg";
import Model42 from '../components/Model42';

import github from "../assets/socials/github.svg";
import malt from "../assets/socials/malt.svg";
import linkedin from "../assets/socials/linkedin.svg";
import Footer from '../components/Footer';
import Typography from '../components/Typography';
import { Form } from '../components/Form';
import GsapContainer from '../components/GsapContainer';

import sopropre from "../assets/creations/sopropre.webp";
import europfacilities from "../assets/creations/europfacilities.webp";
import tetris from "../assets/creations/tetris.webp";
import raycasting from "../assets/creations/raycasting.webp";

function Home() {
  return (
    <GsapContainer>
      <Header />

      <div className='container mx-auto px-4 mb-48 md:mb-96'>
        <div className='flex flex-col gap-60'>
          <Title t1="Mes" t2="projets" />

          {/* <div className="flex flex-col gap-20">
            <div className="scroll-right flex gap-20 justify-end">
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={raycasting} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
            </div>

            <div className="scroll-left flex gap-20">
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={raycasting} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
            </div>

            <div className="scroll-right flex gap-20 justify-end">
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
              <img src={tetris} className="aspect-[4/3] object-cover object-top" width={500} alt="" />
            </div>
          </div> */}

          <Project
            title="So Propre"
            description="Site vitrine pour entreprise de nettoyage"
            url="https://so-propre.fr/"
            image={sopropre}
          />

          <Project
            invert
            title="Europ Facilities"
            description="Site commercial pour maintenance multitechnique"
            url="https://europ-facilities.fr/"
            image={europfacilities}
          />

          <Project
            title="Red Tetris"
            description="Tetris multijoueur"
            url="https://redtetris-production.up.railway.app"
            image={tetris}
          />

          <Project
            invert
            title="CUB3D"
            description="Moteur de jeu utilisant le raycasting"
            url="https://thais-marcon.com/raycasting"
            image={raycasting}
          />

          <Title t1="Mes" t2="Compétences" />

          <div className="skills flex flex-wrap justify-center gap-24">
            <Skill title="WordPress" image={wordpress} />
            <Skill title="React" image={react} />
            <Skill title="Typescript" image={ts} />
            <Skill title="Firebase" image={firebase} />
            <Skill title="TailwindCSS" image={tailwind} />
            <Skill title="GSAP" image={gsapIcon} />
          </div>

          <Title t1="Ma" t2="Formation" />

          <div className="flex flex-col-reverse lg:flex-row-reverse gap-y-20">
            <div className='project-title gap-5 flex flex-col justify-center flex-1 text-center lg:text-left'>
              <Typography variant="lg" className="cursor-scale">
                Expert Informatique et systèmes d'information
              </Typography>

              <Typography className="cursor-scale small">
                Diplômé par l'école 42
              </Typography>
            </div>

            <div className='flex-1'>
              <Model42 />
            </div>
          </div>

          <Title t1="Me" t2="contacter" />

          <Form />

          <div className="skills flex gap-24 md:gap-48 flex-wrap justify-center">
            <Skill title='Malt' url="https://www.malt.fr/profile/thaismarcon" image={malt} />
            <Skill title="Github" url="https://github.com/kingdcreations " image={github} />
            <Skill title='Linkedin' url="https://www.linkedin.com/in/thais-marcon-dev/" image={linkedin} />
          </div>

        </div>
      </div>

      <Footer />
    </GsapContainer>
  )
}

export default Home
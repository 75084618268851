import Typography from "./Typography"

export default function Button({
    href,
    children
}: {
    href: string,
    children: string
}) {
    return (
        <a target='_blank' href={href} className="cursor-scale small bg-slate-800 no-underline group cursor-pointer relative shadow-2xl shadow-zinc-900 rounded-full p-px font-medium leading-6 text-white inline-block">
            <span className="absolute inset-0 overflow-hidden rounded-full">
                <span className="absolute inset-0 rounded-full bg-[image:radial-gradient(75%_100%_at_50%_0%,rgba(56,189,248,0.6)_0%,rgba(56,189,248,0)_75%)] opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
            </span>

            <div className="relative z-10 rounded-full bg-zinc-950 py-5 px-10 ring-1 ring-white/10 ">
                <Typography>{children}</Typography>
            </div>
            
            <span className="absolute -bottom-0 left-[1.125rem] h-px w-[calc(100%-2.25rem)] bg-gradient-to-r from-rose-400/0 via-rose-400/90 to-rose-400/0 transition-opacity duration-500 group-hover:opacity-40" />
        </a>
    )
}
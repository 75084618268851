import { Analytics, getAnalytics } from "firebase/analytics";
import { FirebaseOptions, getApp, getApps, initializeApp } from "firebase/app";
import { Firestore, getFirestore } from "firebase/firestore";
import { createContext, ReactNode } from "react";

export const FirebaseContext = createContext<{
    firestore: Firestore,
    analytics: Analytics
}>(null!);

const FirebaseProvider = ({ config, children }: { config: FirebaseOptions, children: ReactNode }) => {

    const isAppInitialized = getApps().length
    const app = isAppInitialized ? getApp() : initializeApp(config);

    // Public 
    const firestore = getFirestore(app);
    const analytics = getAnalytics(app);
    
    const firebase = {
        firestore,
        analytics
    }

    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider;
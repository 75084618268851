import { addDoc, collection } from "firebase/firestore";
import { useFirebase } from "../context/Firebase/FirebaseConsumer";
import { Input } from "./Input";
import Typography from "./Typography";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";

type Inputs = {
    firstName: string
    lastName: string
    email: string
    message: string
}

export function Form() {
    const firebase = useFirebase()
    const [success, setSuccess] = useState(false)

    const {
        reset,
        control,
        handleSubmit,
        formState: {
            isSubmitting
        },
    } = useForm<Inputs>({
        defaultValues: {
            firstName: "",
            lastName: "",
            email: "",
            message: "",
        },
    })

    const onSubmit: SubmitHandler<Inputs> = async (data) =>
        addDoc(collection(firebase.firestore, "mail"), {
            to: ["mthais.web@gmail.com"],
            from: "Thaïs Marcon <noreply@thais-marcon.com>",
            ReplyTo: data.email,
            message: {
                subject: "Réponse formulaire",
                text: `${data.firstName} ${data.lastName} (${data.email}) vous écris: ${data.message}`
            }
        }).then(() => {
            setSuccess(true)
            reset()
        })


    useEffect(() => {
        ScrollTrigger.refresh()
    }, [success])

    if (!success) return (
        <form className="max-w-4xl w-full mx-auto rounded-2xl p-4 md:p-14 shadow-input bg-black lg:border border-white-100" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-14">
                <Typography className="cursor-scale small text-sm text-neutral-300">
                    Decrivez votre besoin, devis en moins de 24h
                </Typography>

                <div className="flex flex-col gap-10 md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                    <Controller
                        name="firstName"
                        control={control}
                        disabled={isSubmitting}
                        rules={{ required: true }}
                        render={({ field }) => <Input
                            {...field}
                            required
                            autoComplete="on"
                            placeholder="Prénom"
                        />}
                    />

                    <Controller
                        name="lastName"
                        control={control}
                        disabled={isSubmitting}
                        rules={{ required: true }}
                        render={({ field }) => <Input
                            {...field}
                            required
                            autoComplete="on"
                            placeholder="Nom"
                        />}
                    />
                </div>

                <Controller
                    name="email"
                    control={control}
                    disabled={isSubmitting}
                    rules={{ required: true }}
                    render={({ field }) => <Input
                        {...field}
                        required
                        autoComplete="email"
                        type="email"
                        placeholder="Adresse email"
                    />}
                />

                <Controller
                    name="message"
                    control={control}
                    disabled={isSubmitting}
                    rules={{ required: "Message requis" }}
                    render={({ field }) => <Input
                        {...field}
                        required
                        textarea
                        placeholder="Votre besoin"
                    />}
                />

                <Typography variant="small" className="cursor-scale small text-neutral-400">
                    En validant, j'accepte que les informations saisies soient exploitées dans le cadre de la relation commerciale qui peut en découler.
                </Typography>

                <button
                    disabled={isSubmitting}
                    className="cursor-scale small bg-gradient-to-br relative group/btn from-zinc-900 to-zinc-900 block bg-zinc-800 w-full text-white rounded-md h-14 font-medium shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]"
                    type="submit"
                >
                    Envoyer &rarr;
                    <BottomGradient />
                </button>

            </div>
        </form>
    );

    return <div className="max-w-4xl w-full mx-auto rounded-2xl p-4 md:p-14 shadow-input bg-black lg:border border-white-100">
        <Typography variant="lg" className="mb-10 cursor-scale small text-neutral-400">
            Message envoyé
        </Typography>

        <Typography className="cursor-scale small text-neutral-400">
            Votre demande à bien été prise en compte. Je reviens vers vous dans les plus brefs délais
        </Typography>
    </div>
}

const BottomGradient = () => {
    return (
        <>
            <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-rose-500 to-transparent" />
            <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
        </>
    );
};